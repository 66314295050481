import React from 'react'
import { Link, graphql } from 'gatsby'
import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

import './blog-post.css'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <h1 className="blogpost-title">{post.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <div className="tags-container">
          {post.frontmatter.tags.map((tag, index) => (
            <Link to={`/tags/${tag}`} key={index} className="tag-link">
              {tag}
            </Link>
          ))}
        </div>
        <hr />

        <p className="blogpost-date">{post.frontmatter.date}</p>

        {/* <Bio /> */}

        <ul className="post-navigation-list">
          <li>
            {previous && (
              <div className="post-nav">
                <FontAwesomeIcon icon={faChevronLeft} />
                <Link
                  to={previous.frontmatter.url}
                  rel="prev"
                  className="post-nav-link"
                >
                  {previous.frontmatter.title}
                </Link>
              </div>
            )}
          </li>

          {next && (
            <div className="post-nav right">
              <li>
                <Link
                  to={next.frontmatter.url}
                  rel="next"
                  className="post-nav-link"
                >
                  {next.frontmatter.title}{' '}
                </Link>
              </li>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          )}
        </ul>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(frontmatter: { url: { eq: $slug } }) {
      id
      excerpt(pruneLength: 250)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        url
        tags
      }
    }
  }
`
